var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.banners,"page":_vm.page,"server-items-length":_vm.meta.total,"items-per-page":_vm.meta.per_page,"loading":_vm.loading,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('state-indicate',{ref:"indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Banners List")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.editedIndex = -1}}},'v-btn',attrs,false),on),[_vm._v(" New Banner ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Cover image","rules":{required: _vm.editedIndex === -1}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
                            var valid = ref.valid;
return _c('v-file-input',{attrs:{"error-messages":errors,"success":valid,"label":"Cover image"},model:{value:(_vm.editedItem.image),callback:function ($$v) {_vm.$set(_vm.editedItem, "image", $$v)},expression:"editedItem.image"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Target URL","vid":"target_url","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
                            var valid = ref.valid;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"success":valid,"label":"Target URL"},model:{value:(_vm.editedItem.target_url),callback:function ($$v) {_vm.$set(_vm.editedItem, "target_url", $$v)},expression:"editedItem.target_url"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Type","vid":"type","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
                            var valid = ref.valid;
return [_c('v-select',{attrs:{"items":['main', 'selections'],"error-messages":errors,"success":valid,"label":"Type"},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", $$v)},expression:"editedItem.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Active"},model:{value:(_vm.editedItem.is_active),callback:function ($$v) {_vm.$set(_vm.editedItem, "is_active", $$v)},expression:"editedItem.is_active"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":isLoading},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid,"loading":isLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.editedIndex > -1 ? 'Save' : 'Create')+" ")])],1)],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":isLoading,"text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","loading":isLoading,"text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]}}])})]},proxy:true},{key:"item.image",fn:function(ref){
                            var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image_url,"width":"200"}})]}},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset ")])]},proxy:true}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.meta.last_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }