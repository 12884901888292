import axios from "axios";

export default {
  update(id, data) {
    return axios.post(`/api/banners/${id}`, data)
  },
  store(data) {
    return axios.post(`/api/banners`, data)
  },
  delete(id) {
    return axios.delete(`/api/banners/${id}`)
  },
  list() {
    return axios.get('/api/banners/list')
  }
}
